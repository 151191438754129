import './App.css';
import RoutesElement from './library/routes/routes';


function App() {
  return (
    <RoutesElement/>
  );
}

export default App;
