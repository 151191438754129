import "./index.css";

import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import customTheme from "./theme";

const root = ReactDOM.createRoot(
  document.getElementById("custom-activities") as HTMLElement
);
root.render(
  <BrowserRouter>
    <ChakraProvider resetCSS={false} theme={customTheme}>
      <App />
    </ChakraProvider>
  </BrowserRouter>
);

reportWebVitals();
