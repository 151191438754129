import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

// import ActivitiesSettings from "../../pages/activities-settings";
//import CommissionActivities from "../../pages/commission-activities";
import Loading from "../../pages/loading";

// W celu optymalizacji ładowania stron, podczas
// budowania projektu wszystkie strony powinny być ładowane
// w trybie "lazy", co umożliwia nie ładowanie dodatkowych elementów
// kiedy nie są one potrzebne.

// const Example = lazy(() => import("../../pages/example")); //PROD MODE
const ActivitiesSettings = lazy(
  () => import("../../pages/activities-settings")
);
const CommissionActivities = lazy(
  () => import("../../pages/commission-activities")
);

function RoutesElement() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route
          path="/accountCompany/show/company_id/:id"
          element={<ActivitiesSettings />}
        />
        <Route
          path="/commission/show/commission_id/:id"
          element={<CommissionActivities />}
        />
      </Routes>
    </Suspense>
  );
}

export default RoutesElement;
